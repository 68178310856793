import Swiper, { Navigation, Pagination, Thumbs, EffectFade, Controller } from 'swiper'

const SLIDE_MAIN_SELECTOR = '.js-swiper-main'
const SLIDE_THUMB_SELECTOR = '.js-swiper-thumbs'

class ResultVisible extends HTMLElement {
  constructor () {
    super ()

    this.slideMainEl = this.querySelector(SLIDE_MAIN_SELECTOR)
    this.slideThumbEl = this.querySelector(SLIDE_THUMB_SELECTOR)
    this.thumbs = null
    this.swiper = null

    this.thumbsOptions = {
      slidesPerView: 1,
      spaceBetween: 0,
      slideToClickedSlide: true,
      effect: 'fade',
      navigation: {
        nextEl: '.js-swiper-button-next-result',
        prevEl: '.js-swiper-button-prev-result'
      },
      modules: [Navigation,EffectFade,Controller]
    }

    this.slideMainEl && this.slideThumbEl && this.initSlide()
  }

  initSlide () {
    this.thumbs = new Swiper(this.slideThumbEl, this.thumbsOptions)
    this.swiper = new Swiper(this.slideMainEl, {
      slidesPerView: 1,
      keyboardControl: true,
      spaceBetween: 0,
      effect: 'fade',
      allowTouchMove: true,
      pagination: {
        el: '.js-swiper-pagination-result',
        clickable: true
      },
      modules: [Pagination,Thumbs,EffectFade,Controller]
    })
    
    this.swiper.controller.control = this.thumbs;
    this.thumbs.controller.control = this.swiper;
  }
}

customElements.define('result-visible', ResultVisible)
